import React, { useCallback, useContext } from "react";
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import { HubSpotCallToAction } from "components/common/HubSpotCallToAction";
import { AppContext } from "services/appContext/AppContext";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork

export default function Footer() {
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  const intl = useIntl();
  const isLoggedIn = appContext.localStorageInfo.user;

  const isFreeUser = useCallback(()=> appContext.localStorageInfo.selectedProfile?.external,[ appContext.localStorageInfo.selectedProfile]);

  const hubspotParameter = useCallback((parameterName: string)=> {
    const userType = isFreeUser() ? "freeUser" : "paidUser";
    return intl.formatMessage({id: `adminNavBar.hubSpot.callToAction.${userType}.${parameterName}`});
  },[intl, isFreeUser]);

  return (
    <footer className="mt-3" id="footer-main">
      <Container fluid>
        <Row className="align-items-center">
          <Col xs="12" md="4">
            <div className={`copyright d-flex justify-content-center justify-content-md-start ${theme === "light" ? "text-crow" : "text-muted"}`}>
             {isMixNetworkUrl ? "Copyright" : "©"} {new Date().getFullYear()}
              <div className={`ml-1 ${theme === "light" ? "text-crow" : ""}`}>
                <FormattedMessage id={isMixNetworkUrl ? "footer.copyright.mixNetwork" : "footer.copyright"}/>
              </div>
              {!isMixNetworkUrl && (
                <Link
                  className={`font-weight-bold ml-1 ${theme === "light" ? "lblue-color" : ""}`}
                  to="/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <FormattedMessage id="footer.portControl" /> {process.env.REACT_APP_VERSION}
              </Link>
              )}
            </div>
          </Col>
          <Col xs="12" md="4">
            {!isMixNetworkUrl && isLoggedIn &&
            <div className="nav-footer justify-content-center">
              <div className="hubspot-banner">
                <HubSpotCallToAction
                  portalId={hubspotParameter("portalId")}
                  ctaToken={hubspotParameter("ctaToken")}
                />
              </div>
            </div>
              }
          </Col>
          <Col xs="12" md="4">
            <Nav className="nav-footer justify-content-center justify-content-md-end">
              <NavItem>
              <NavLink href={isMixNetworkUrl ? "https://mixnetworks.com/about/" : "https://numhub.com/about"} target="_blank">
                  <FormattedMessage id="footer.aboutus" />
                </NavLink>
              </NavItem>
              <NavItem>
              <NavLink href={isMixNetworkUrl ? "https://mixnetworks.com/privacy-policy/" : "https://numhub.com/privacy-policy"} target="_blank">
                  <FormattedMessage id="footer.privacyPolicy" />
                </NavLink>
              </NavItem>
              {!isMixNetworkUrl && (
                <>
                  <NavItem>
                    <NavLink href="https://numhub.com/dpa" target="_blank">
                      <FormattedMessage id="footer.dpa" />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="https://numhub.com/porting-saas-license" target="_blank">
                      <FormattedMessage id="footer.SaaS Agreement" />
                      </NavLink>
                  </NavItem>
                </>
              )}
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
