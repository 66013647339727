import React, { useCallback } from "react";
import DateTimeInput from "../forms/DateTimeInput";
import { getUtcDate, sort, isBetween } from "services/util/DateUtil";
import { TableIdentifiers } from "services/uiSettings/TableIdentifiers";
import { RestoreFilterService } from "services/restoreFilters/RestoreFilterService";
import { ColumnFilterChangeEvent } from "./RestoreTableColumnFilter";

type Props = {
  initialState: {
    identifier: string
  },
  column: {
    filterValue: Array<Date | undefined>;
    preFilteredRows: Array<any>;
    setFilter: React.Dispatch<React.SetStateAction<Array<Date | undefined>>>;
    id: string;
  },
  onChange:(props: ColumnFilterChangeEvent)=>void;
  columnFilterOptions?: any[];
  className?: string;
};

export default function DateRangeColumnFilter(props: Props) {

  const identifier = props.initialState.identifier;

  const canRestoreFilter = identifier === TableIdentifiers.Orders || identifier === TableIdentifiers.WorkQueue || identifier === TableIdentifiers.WebhookLogs;

  const [min, max] = React.useMemo(() => {
    const values = props.columnFilterOptions 
    ? props.columnFilterOptions
    : props.column.preFilteredRows.length
          ? props.column.preFilteredRows.map((x) => x.values[props.column.id])
          : [];

    let min = getUtcDate(values.length ? (sort(values as Array<Date>)[0] as Date) : undefined);
    let max = getUtcDate(values.length ? (sort(values as Array<Date>, true)[0] as Date) : undefined);
    
    return [min, max];
    // eslint-disable-next-line
  }, [props.columnFilterOptions, props.column.preFilteredRows]);

  
  const handleMinDateChange = (dateMin) => {
    
    // setting react table column's filterValue property array for max i.e. [max, min]
    const filterValueMax = props.column && props.column?.filterValue && props.column?.filterValue[0] 
          ? props.column?.filterValue[0]
          : max

    props.column.setFilter([filterValueMax, dateMin]);

    // if sticky filter is available for the table, then use sticky filter stored state values instead
    if (canRestoreFilter) {
      var values = RestoreFilterService.getFilterValue(identifier, props.column.id);
      var previousMaxDate =   values[0] ?? max;
      RestoreFilterService.setFilterDate(props.initialState.identifier, props.column.id, previousMaxDate, dateMin);
    }

    // triggering event for registered handlers
    if (props && props.onChange)
        {
            props?.onChange({
                tableIdentifier: identifier,
                columnId: props.column.id,
                filteredValue: filterValueMax +","+ dateMin
            } as ColumnFilterChangeEvent);
    
        }
  }

  const handleMaxDateChange = (dateMax) => {
    
    // setting react table column's filterValue property array for min i.e. [max, min]
    const filterValueMin = props.column && props.column.filterValue && props.column.filterValue[1] 
          ? props.column?.filterValue[1]
          : min

    props.column.setFilter([dateMax, filterValueMin]);
    
    // if sticky filter is available for the table, then use sticky filter stored state values instead
    if (canRestoreFilter) {
      var values = RestoreFilterService.getFilterValue(identifier, props.column.id);
      var previousMinDate = values[1] ?? min;
      RestoreFilterService.setFilterDate(props.initialState.identifier, props.column.id, dateMax, previousMinDate);
    }

    // triggering event for registered handlers
    if (props && props.onChange)
        {
            props?.onChange({
                tableIdentifier: identifier,
                columnId: props.column.id,
                filteredValue: dateMax +","+ filterValueMin
            } as ColumnFilterChangeEvent);
    
        }
  }

  const getMinDateInput= useCallback(()=>{
    if (canRestoreFilter) {
      var values = RestoreFilterService.getFilterValue(identifier, props.column.id);
      return values && values[1] ? getUtcDate(values[1]) : min;
    }
    else {
      return props.column.filterValue && props.column.filterValue[1] 
        ? getUtcDate(props.column.filterValue[1]) 
        : min;
    }
      
    
  },[props.column.filterValue, min, canRestoreFilter, identifier, props.column.id]);

  const getMaxDateInput= useCallback(()=>{
    if (canRestoreFilter) {
      var restoredValues = RestoreFilterService.getFilterValue(identifier, props.column.id);
      return restoredValues && restoredValues[0] ? getUtcDate(restoredValues[0]) : max;
    }
    else{
        return props.column.filterValue && props.column.filterValue[0]
      ? getUtcDate(props.column.filterValue[0]) 
      : max;
    }
    
  },[props.column.filterValue, max, canRestoreFilter, identifier, props.column.id]);

  const getDateTimeInputStyle = ()=> {
    var filterIsSet = props.column?.id && 
          RestoreFilterService.hasFilter(identifier) && 
          RestoreFilterService.hasFilterDateColumn(identifier, props.column.id) &&
          RestoreFilterService.getFilterValue(identifier, props.column.id).length > 0;
    return canRestoreFilter && (props.column.filterValue || filterIsSet) ? "filtered-column_bg" : "";
  }

  return (
    <div className="d-flex justify-content-center">
      <DateTimeInput
        className={`${getDateTimeInputStyle()} ${props.className}`}
        value={getMinDateInput()}
        onChange={(val) => {handleMinDateChange(val?.toDate())}}
        // isValidDate={(currentDate) => {
        //   let isValid = true;
        //   if (!isBetween(currentDate, min, max)) {
        //     isValid = false;
        //   }
        //   if (
        //     props.column.filterValue &&
        //     !currentDate.isSameOrBefore(props.column.filterValue[1])
        //   ) {
        //     isValid = false;
        //   }
        //   return isValid;
        // }}
      />
      <span className="m-1">to</span>
      <DateTimeInput
        className={`${getDateTimeInputStyle()} ${props.className}`}
        value={getMaxDateInput()}
        //value={(props.column.filterValue && getUtcDate(props.column.filterValue[0])) || max}
        onChange={(val) => {handleMaxDateChange(val?.toDate())}}
        // isValidDate={(currentDate) => {
        //   let isValid = true;
        //   if (!isBetween(currentDate, min, max)) {
        //     isValid = false;
        //   }
        //   if (props.column.filterValue && !currentDate.isSameOrAfter(props.column.filterValue[0])) {
        //     isValid = false;
        //   }
        //   return isValid;
        // }}
      />
    </div>
  );
}

export const dateRangefilterFunction = (rows: any[], accessor: any, filterValues: any) =>  
  rows.filter((x) => {
    return (
      x.values[accessor] &&
      isBetween(getUtcDate(x.values[accessor]), filterValues[1], filterValues[0])
    );
  });
