import React, { useCallback, useEffect } from "react";
import { getUniqueElements } from "services/util/ArrayUtil";
import DropdownInput from "../forms/DropdownInput";
import { RestoreFilterService } from "services/restoreFilters/RestoreFilterService";

export type ColumnFilterChangeEvent = {
    tableIdentifier: string,
    columnId: string,
    filteredValue: string
};

type Props = {
    initialState: {
        identifier: string
    }
    column: {
        filterValue: string;
        preFilteredRows: Array<any>;
        setFilter: (value: string | undefined) => void;
        id: string;
    },
    onChange:(props: ColumnFilterChangeEvent)=>void;
    columnFilterOptions?: any[];
};

export default function RestoreTableColumnFilter(props: Props) {

    const identifier = props.initialState.identifier;

    useEffect(() => {
        const options = props?.columnFilterOptions ?? getUniqueElements(props.column.preFilteredRows.map((x) => (
            x.values[props.column.id])
        ));
        if (RestoreFilterService.hasFilterColumn(identifier, props.column.id) && options.some(x => x === RestoreFilterService.getFilterValue(identifier, props.column.id))) {
            props.column.setFilter(RestoreFilterService.getFilterValue(identifier, props.column.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.column, props.column.filterValue, props?.columnFilterOptions, identifier, RestoreFilterService.getFilterIdentifier(props.initialState.identifier)])

    const options = React.useMemo(() => {
        const options = props?.columnFilterOptions ?? props.column.preFilteredRows.map((x) => (
            x.values[props.column.id]
        ));
        return [
            { key: "Any", value: "" },
            ...getUniqueElements(options)
                .filter((x) => x)
                .sort((a, b) => (a > b ? 1 : -1))
                .map((x, i) => {
                    return { value: x, key: x };
                })
        ];

        
     }, // eslint-disable-next-line
     [props, props.column.id, props.columnFilterOptions, props.column.preFilteredRows]);

    const handleChangePortingTypes = (filterValue: any, column: any) => {
        props.column.setFilter(filterValue || undefined);
        RestoreFilterService.setFilterValue(props.initialState.identifier, props.column.id, filterValue || "Any");
        
        if (props && props.onChange)
        {
            props?.onChange({
                tableIdentifier: identifier,
                columnId: props.column.id,
                filteredValue: filterValue
            } as ColumnFilterChangeEvent);
    
        }
    }

    const hasValidFilterValue = useCallback(()=> props.column.filterValue && props.column.filterValue !== 'Any' && props.column.filterValue !== '',
    [props.column.filterValue])

    return (
        <DropdownInput
            sm
            className={`${hasValidFilterValue() ? "filtered-column_bg" : ""} ${"text-align-center filter-align"}`}
            onChange={(e) => {
                handleChangePortingTypes(e.target.value, props.column);
            }}
            value={props.column.filterValue}
            options={options}
        />
    );
}


export const restoreTableColumnFilterFunction = (rows: any[], accessor: any, filterValues: any) => (
    rows.filter((x) => {
        if (filterValues === x.values[accessor] || filterValues === '' || filterValues === 'Any') {
            return (
                x.values
            );
        }
        return '';
    })
)


