import React, { useState, FormEvent, useContext, useEffect } from "react";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { InventoryType } from "../types/InventoryType";
import InventoryModal from "./InventoryModal";
import InventoryTable from "./InventoryTable";
import classNames from "classnames";
import { AppContext } from "services/appContext/AppContext";
import { Route, Switch } from 'react-router-dom';
import PooledTable from "./PooledTable";
import { HashRouter } from "react-router-dom";
import { PermissionType } from "services/authorization/PermissionType";
import Navbar from "components/common/navBar/NavBar";

export default function Inventory() {
  const [searchValue, setSearchValue] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const [focusedSearchInput, setFocusedSearchInput] = useState(false);
  const [errorMessageId, setErrorMessageId] = useState("");
  const intl = useIntl();
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  const [showDropdown, setShowDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loadTable, setLoadTable] = useState(false);
  const [isPooled, setIsPooled] = useState(false);
  const [isInventory, setIsInventory] = useState(true);
  const [isDNO, setIsDNO] = useState(false);
  const [inventoryType, setInventoryType] = useState<InventoryType>();
  const dropdownToggle = () => setShowDropdown((prevState) => !prevState);
  const handleCreateDropdownItemButtonClick = (inventoryType: InventoryType) => {
    setShowModal(true);
    setShowDropdown(false);
    setInventoryType(inventoryType);
  };

  const navTitlesRoutes = [
    { routes: '/Inventory', titles: 'Inventory Numbers' },
    { routes: '/pooled', titles: 'Pooled Numbers' },
    // Add more navigation items as needed
  ];

  useEffect(() => {
    const isDNO = appContext.localStorageInfo.user?.permissions.includes(PermissionType.RealNumberDNO);
    isDNO && setIsDNO(isDNO);
  },
    [appContext.localStorageInfo.selectedProfile, appContext]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const inputValue = searchInputValue.trim();
    const errorMessageIdResult = validateSearchValue();
    setErrorMessageId(errorMessageIdResult);

    if (!errorMessageIdResult) {
      setSearchValue(inputValue);
    }
  };

  const handleSearchChange = (searchValue: string) => {
    setErrorMessageId("");
    setSearchInputValue(searchValue);
  };


  return (
    <>
      <BreadcrumbHeader>
        <FormattedMessage id="spids.title" />
      </BreadcrumbHeader>
      <Container className="mb-4">
        <Row>
          <Col md="6" className="offset-md-3">
            <h1 className={`display-3 ${theme === "light" ? "lblue-color" : "pc-network-explorer-bg"}`}>
              <FormattedMessage id="spids.title" />
            </h1>
          </Col>
        </Row>
        <Row>
        <Col md="3" xs="12" className="mb-1">
          { isInventory && !isPooled && isDNO &&
            <div className="pc-create-dropdown">
              <Dropdown isOpen={showDropdown} toggle={dropdownToggle}>
                <DropdownToggle className="cursor-pointer pb-0 pt-0v" tag="span">
                  <Button color={`${theme === "dark" ? "warning" : ""}`} className={`pc-btn ${theme === "light" ? "bg-lblue" : ""}`}>
                    <i className="fas fa-plus-square mr-2" />
                    <FormattedMessage id="notifications.create" />
                  </Button>
                </DropdownToggle>
                <DropdownMenu className={`dropdown-menu-lg dropdown-menu-white ${theme === "light" ? "bg-dropdown text-crow" : "bg-dark text-white pc-no-shadow"}`}>
                  <Row className="shortcuts px-4">
                    <Col
                      className="shortcut-item cursor-pointer"
                      onClick={() =>
                        handleCreateDropdownItemButtonClick(InventoryType.DNO)
                      }
                      xs="4">
                      <span className="shortcut-media avatar rounded-circle bg-gradient-pc-blue">
                        <i className="fas fa-solid fa-upload" />
                      </span>
                      <small>
                        <FormattedMessage id="inventory.create.dno" />
                      </small>
                    </Col>
                  </Row>
                </DropdownMenu>
              </Dropdown>
            </div>
          }
        </Col>
        <Col md="6" xs="12" className="mb-1">
            <Form onSubmit={handleSubmit}>
              <FormGroup
                className={classNames("input-group-merge pc-search-container mb-0", {
                  focused: focusedSearchInput
                })}>
                <InputGroup className="input-group-merge">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    className="pc-search"
                    placeholder={intl.formatMessage({ id: "inventory.search.placeholder" })}
                    value={searchInputValue}
                    onChange={(e) => handleSearchChange(e.currentTarget.value)}
                    onFocus={() => setFocusedSearchInput(true)}
                    onBlur={() => setFocusedSearchInput(false)}
                  />
                </InputGroup>
                <Button color={`${theme === "dark" ? "warning" : ""}`} type="submit" className={`pc-btn ml-4 ${theme === "light" ? "bg-lblue" : ""}`}>
                  <FormattedMessage id="inventory.search.button" />
                </Button>
              </FormGroup>
              {errorMessageId && (
                <div className="text-red text-sm mt-1">
                  <FormattedMessage id={errorMessageId} />
                </div>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        {showModal && inventoryType && (
          <InventoryModal
            closeModal={() => setShowModal(false)}
            loadTable={() => setLoadTable(true)}
          />
        )}
        <HashRouter>
          <Navbar navTitlesRoutes= {navTitlesRoutes}/>
            <Switch>
              <Route path='/Inventory' exact render={(props) => (
                <InventoryTable 
                  search={searchValue}
                  loadTable={loadTable}
                  isDNO={isDNO}
                  isInventory={() => setIsInventory(true)}
                  isPooled={() => setIsPooled(false)}
                />
                )}/>
              <Route path='/pooled' exact render={(props) => (
                <PooledTable
                  search={searchValue}
                  isPooled={() => setIsPooled(true)}
                  isInventory={() => setIsInventory(false)}
                />
              )}/>
            </Switch>
        </HashRouter>
      </Container>
    </>
  );
}

const validateSearchValue = (): string => {
  return "";
};