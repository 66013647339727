import React from "react";
import { getUniqueElements } from "services/util/ArrayUtil";
import DropdownInput from "../forms/DropdownInput";
import { TppSpidList } from "services/apis/types/tpp/TppSpidList";

type Props = {
  column: {
    filterValue: string;
    preFilteredRows: Array<any>;
    setFilter: (value: string | undefined) => void;
    id: string;
    spidsFilterList?: TppSpidList,
  },
};

export default function TppSpidColumnFilter(props: Props) {
  const options = React.useMemo(() => {
    if (props.column.spidsFilterList && props.column.spidsFilterList[0].spids && props.column.spidsFilterList[0].spids.length > 0) {
      return [
        { key: "Any", value: "Any" },
        ...getUniqueElements(props.column.spidsFilterList[0].spids)
          .map((x) => {
            return { value: x, key: x };
          })
      ];
    }
    return [{ key: "Any", value: "Any" }];
  }, [props.column.spidsFilterList]);

  return (
    <DropdownInput
      sm
      onChange={(e) => {
        props.column.setFilter(e.target.value || undefined);
      }}
      value={props.column.filterValue}
      options={options}
      className="text-align-center filter-align"
    />
  );
}