import React, { useState, FormEvent, useContext } from "react";
import {
  Row,
  Col,
  Container,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Form,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import AuditTable from "./AuditTable";
import classNames from "classnames";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import Authorize from "components/framework/authorization/Authorize";
import CreateAuditModal from "./CreateAuditModal";
import { PermissionType } from "services/authorization/PermissionType";
import { AppContext } from "services/appContext/AppContext";

export default function AuditList() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [focusedSearchInput, setFocusedSearchInput] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const intl = useIntl();
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setSearchValue(searchInputValue.trim());
  };

  const dropdownToggle = () => setShowDropdown((prevState) => !prevState);

  const handleCreateDropdownItemButtonClick = () => {
    setShowModal(true);
    setShowDropdown(false);
  };

  return (
    <>
      <BreadcrumbHeader>
        <FormattedMessage id="audits.title" />
      </BreadcrumbHeader>
      <Container className="mb-5">
        <Row>
          <Col md="6" className="offset-md-3">
            <h1 className={`display-3 ${theme === "light" ? "lblue-color" : "text-blue"}`}>
              <FormattedMessage id="audits.title" />
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md="3" xs="12" className="mb-1">
            <Authorize spidPermissions={[PermissionType.SOAWrite]}>
              <div className="pc-create-dropdown">
                <Dropdown isOpen={showDropdown} toggle={dropdownToggle}>
                  <DropdownToggle className="cursor-pointer pb-0 pt-0v" tag="span">
                    <button className={`btn ${theme === "light" ? "bg-lblue text-white" : "pc-btn-audit"}`}>
                      <i className="fas fa-plus-square mr-2" />
                      <FormattedMessage id="audits.create" />
                    </button>
                  </DropdownToggle>
                  <DropdownMenu className={`dropdown-menu-lg dropdown-menu-white ${theme === "light" ? "bg-dropdown text-crow" : "bg-dark text-white pc-no-shadow"}`}>
                    <Row className="shortcuts px-4">
                      <Col
                        className="shortcut-item cursor-pointer"
                        onClick={() => handleCreateDropdownItemButtonClick()}
                        xs="4">
                        <span className="shortcut-media avatar rounded-circle bg-gradient-pc-blue">
                          <i className="fa fa-check-double" />
                        </span>
                        <small>
                          <FormattedMessage id="audits.create.audit" />
                        </small>
                      </Col>
                    </Row>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Authorize>
          </Col>
          <Col md="6" xs="12" className="mb-1">
            <Form onSubmit={handleSubmit}>
              <FormGroup
                className={classNames("input-group-merge pc-search-container mb-0", {
                  focused: focusedSearchInput
                })}>
                <InputGroup className="input-group-merge">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    className="pc-search"
                    placeholder={intl.formatMessage({ id: "audits.search.placeHolder" })}
                    value={searchInputValue}
                    onChange={(e) => setSearchInputValue(e.currentTarget.value)}
                    onFocus={() => setFocusedSearchInput(true)}
                    onBlur={() => setFocusedSearchInput(false)}
                  />
                </InputGroup>
                <button className={`btn ml-4 ${theme === "light" ? "bg-lblue text-white" : "pc-btn-audit"}`} type="submit">
                  <FormattedMessage id="audits.search.button" />
                </button>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Container>
      <Container>
        <AuditTable triggerRefresh={triggerRefresh} searchValue={searchValue} />
        {showModal && (
          <CreateAuditModal
            closeModal={() => setShowModal(false)}
            triggerRefresh={() => setTriggerRefresh((p) => !p)}
          />
        )}
      </Container>
    </>
  );
}
